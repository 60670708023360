body {
	font-family: 'Inter', sans-serif;
	font-style: Regular;
	font-size: 20px;
  margin: 0;
  padding: 0;
  background-color: #0d0d0d;
  color: #fff;
}

a {
	color: #ef9273;
	text-decoration: none;
  z-index: 99999;
}
a:hover {
  color: #fff;
}

a:active {
	color: #fff;
	text-decoration: underline;
}

html {
  scroll-behavior: smooth;
}

.container-1 {
  height: 75vh;
  width: 100%;
  font-weight: 700;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subheader {
  font-weight: 600;
  font-size: 30px;
  margin-top: -30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  color: #ef9273;
  margin-left: 0.5rem;
}

.links {
  font-weight: 600;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle {
  font-weight: 600;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11%;
  color: #ef9273;
}

.wave {
  animation-name: wave-animation;  
  animation-duration: 2.5s;        
  animation-iteration-count: infinite;  
  transform-origin: 70% 70%;       
  display: inline-block;
}

@keyframes wave-animation {
    0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }  
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }  
  100% { transform: rotate( 0.0deg) }
}

